import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/views/Layout.vue'),
    redirect: {name: 'home'},
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: '/non-key-tool',
        name: 'nonkeyTool',
        component: () => import('@/views/NonkeyTool.vue'),
      },
      {
        path: '/assets-list',
        name: 'assetsList',
        component: () => import('@/views/AssetsList.vue'),
      },
      {
        path: '/assets-check',
        name: 'assetsCheck',
        component: () => import('@/views/AssetsCheck.vue'),
      },
      {
        path: '/assets-life',
        name: 'assetsLife',
        component: () => import('@/views/AssetsLife.vue'),
      },
      {
        path: '/check-record',
        name: 'checkRecord',
        component: () => import('@/views/CheckRecord.vue'),
      },
      {
        path: '/nonkey-check-record',
        name: 'nonkeyCheckRecord',
        component: () => import('@/views/NonkeyCheckRecord.vue'),
      },
      {
        path: '/sys-user',
        name: 'sysUser',
        component: () => import('@/views/SysUser.vue'),
      },
      {
        path: '/sys-config',
        name: 'sysConfig',
        component: () => import('@/views/SysConfig/Index.vue'),
      },
      {
        path: '/personal-center',
        name: 'personalCenter',
        component: () => import('@/views/PersonalCenter.vue'),
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import('@/views/Notice.vue'),
      },
      {
        path: '/integral',
        name: 'integral',
        component: () => import('@/views/Integral.vue'),
      },
      {
        path: '/employee-feedback',
        name: 'employeeFeedback',
        component: () => import('@/views/EmployeeFeedback.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
